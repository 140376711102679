.invite-page {
  text-align: center;
  color: #1d1c1d;

  img {
    animation: .8s cubic-bezier(.455, .03, .515, .955) .8s infinite workspace-people-bump;
    outline: 2px solid rgba(var(--sk_primary_background, 255, 255, 255), 1);
    border-radius: 8px;
    height: 48px;
    width: 48px;
  }
}
h1 {
  font-size: 48px;
}
.highlight {
  color: #3050D2;
}
.sub-heading {
  color: #454245;
  font-size: 18px;
}
.friends-text {
  font-size: 15px;
  line-height: 1.46668;
  font-weight: initial;
  color: rgba(29, 28, 29, .7);
}
@keyframes workspace-people-bump {
  0%, 30%, 100% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(-3px);
  }
}
.invite {
  font-size: 15px;
  line-height: 1.46668;
  font-weight: initial;
  color: rgba(29, 28, 29, 1);
  text-align: center;
  margin-bottom: 42px;
}
.disclosure {
  font-size: 12px;
  line-height: 1.50001;
  font-weight: initial;
  color: rgba(29, 28, 29, .7);
  max-width: 420px;
  margin: 10px auto;
}