// Always on custom tooltip
.tooltip-container {
  text-align: center;

  .tooltip-always-on {
    border-radius: 4px;
    background: #18181B;
    color: #FFF;
    font-size: 12px;
    font-weight: 500;
    line-height: 125%;
    padding: 6px 12px;
    display: inline-block;
  }

  .tooltip-caret {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #18181B;
    margin: auto;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}

// Floating animation
@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-6px);
  }
}
.float-animation {
  animation: float 3s ease-in-out infinite;
}

// Custom SVG List
ul.custom-svg-list {
  padding-inline-start: 0;

  li {
    padding: 8px 0;
  }
}
ul li {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 10px;
}

// Custom card container
.mambo-card-container {
  display: flex;
  border-radius: 12px;
  background: #FFF;
  /* box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.10);*/
  box-shadow: 0px -1px 0px 0px #EBECF1 inset, 0px 2px 8px 0px #E2E8F3;

  &.dark {
    background: #F7F8FA;
  }

  .card-header-row {
    display: flex;
    padding: 20px;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    .card-title {
      color: #1F2024;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      margin-bottom: 0;
    }
    .card-action {
      color: #2281C8;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
    }
  }
}

// Text links not in an anchor tag
.text-link {
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

// Mambo Chips
.mambo-chip {
  padding: 10px 20px;
  border-radius: 16px;
  &.warning {
    background-color: rgba(255, 231, 230, 0.7);
    color: #ff5757;
  }
}
