.credit-card-container {
  display: flex;
  align-items: center;
  padding-left: 18px;

  .card-icon {
    width: 40px;
    height: 40px;
    margin-right: 14px;
    background-size: cover;
    color: #434A55;
  }

  .card-details {
    display: flex;
    flex-direction: column;
  }

  .card-number {
    color: #434A55;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .card-info {
    color: #7D7D7D;
    font-size: 14px;
    font-weight: 500;
    line-height: 140%;
  }

  .card-expiry {
    margin-left: 5px;
  }
}
.next-payment-text {
  color: #3E3F42;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.payment-amount {
  color: #3E3F42;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.payment-date {
  color: #3E3F42;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}
.invoice-list {
  padding-left: 20px;
}
.invoice-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  color: #3E3F42;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 4px;

  .invoice-amount {
    color: #7E7E7E;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}