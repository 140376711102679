// Override the default PrimeReact theme variables here

@import 'variables';
// Fonts

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

// Import the default variables from PrimeReact
@import '~primereact/resources/themes/soho-light/theme.css';

// Override variables
$font-family: 'Poppins', sans-serif;
$body-font-family: 'Poppins', sans-serif;
$font-size: 14px;

:root {
  --font-family: 'Poppins', sans-serif;
}

// You can also override colors
// $text-color: #333333;
$primary-color: #311B92;
$text-gray: #66676A;

// Import the rest of the PrimeReact styles
@import '~primereact/resources/primereact.min';
@import '~primeicons/primeicons';
@import 'primeflex/primeflex';

// Mambo AI Custom Components
@import 'components';
@import 'styles';
