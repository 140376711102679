// Style for views/Plan.tsx (Plan Page)

.plan-page {
  text-align: center;
  font-family: 'Poppins', sans-serif;

  .container {
    max-width: 1220px;
  }
  .p-card-body {
    width: 100%;
  }
  .p-card-content {
    display: flex;
  }

  .info {
    color: #4A3AFF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 111.111% */
    letter-spacing: 1.8px;
    text-transform: uppercase;
  }
  .heading {
    color: #170F49;
    text-align: center;
    font-size: 46px;
    font-style: normal;
    font-weight: 600;
    line-height: 62px; /* 124% */
  }
  .warning {
    color: #E71919;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 166.667% */
  }

  .disclosure {
    color: #7D7D7D;
    font-size: 13px;
    font-style: normal;
    font-weight: 330;
    line-height: normal;
    text-align: left;
    margin: 24px auto;
    max-width: 400px;
  }
  .p-inputnumber-buttons-stacked .p-inputnumber-button-group {
    flex-direction: row;
  }
  .pricing-row {
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: space-between;
    justify-content: space-between;
  }
  .number-toggle {
    width: 100px;
  }
  .number-toggle-plus {
    border-radius: 0;
    border: 1px solid #d1d5db;
    border-left: none;
    border-right: none;
    background: #F8F8F8;
    color: #7F7F7F;
  }
  .number-toggle-minus {
    border-radius: 0 0.25rem 0.25rem 0;
    background: #C1C1C1;
    color: #F4F4F4;
    border: 1px solid #d1d5db;
    border-left: none;
    border-right: none;
  }
  .subscribe-extras {
    display: flex !important;
    text-align: center;
    margin: 20px auto 40px auto;
    max-width: 820px;
    justify-content: center;

    span {
      text-align: left;
      font-weight: 600;
      margin-left: 16px;
      font-size: 14px;
      max-width: 170px;
    }
  }
  .light-text {
    color: #6F6C90;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }
}

.left-half {
  background-color: #ffffff;
  flex: 1.10;
  padding: 3rem 1rem 3rem 2rem;
  border-radius: 12px 0 0 12px;
}

.right-half {
  background-color: rgba(101, 44, 211, 0.1);
  flex: 0.90;
  padding: 3rem 1rem;
  border-radius: 0 12px 12px 0;

  .price-quote {
    margin: 20px 0;

    .amount {
      color: #171923;
      font-size: 34px;
      font-style: normal;
      font-weight: 800;
      line-height: 100%;
      letter-spacing: -0.4px;
      display: block;
      span {
        font-size: 18px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.2px;
      }
    }
    .annual {
      color: #A1A1AA;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      display: block;
    }
    .cancel {
      color: #171923;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      display: block;
    }
  }
}
.selection-container {
  position: relative;
  display: inline-block;

  .tooltip-container {
    top: 50%;
    left: 50%;
    transform: translate(-28%, -10%);
  }
}
