// variables.scss

// colors
$main: #311B92;
$main-hover: #5E35B1;
$main-light: #ede7f6;
$secondary: #2E7D32;
$red: #F44336;
$text: #333333;
$text-headers: #212121;

// breakpoints
$bp-xxs: 420px;
$bp-xs: 576px;
$bp-sm: 700px;
$bp-md: 768px;
$bp-lg: 992px;
$bp-xl: 1140px;
$bs-xxl: 1200px;

// border-radius
$br-buttons: 10px;
