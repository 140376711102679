// General
.mambo-container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

// Navbar
.navbar {
    background-color: #eff1f5;
    padding: 1.375rem 1rem;
    box-shadow: 0 1px 2px 0 #dfe3ec;

    .logo {
        width: 2.25rem;
        height: 2.25rem;
    }

    @media (min-width: $bp-md) {
        padding: 1.375rem 0;
    }
}
.navbar-nav {
    padding-left: 30px;
}
.wordmark {
    color: #000000;
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 10px;
}
.navbar-right {
    align-items: center;
}
.nav-user-profile {
    max-width: 2.785rem;
    border-radius: 36.117px;
    border: 2px solid rgba(165, 172, 184, 0.20);
}
.navbar-nav {
    .dropdown-menu {
        right: 0;
        left: unset;
    }
}
.nav-link.active {
    border-bottom: 3px solid $main;
}
.workspace-info {
    .workspace-logo {
        width: 30px;
        height: 30px;
        border-radius: 6px;
        margin-right: 8px;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    }
    .workspace-name {
        font-size: 14px;
        color: #041433;
        padding: 0 0 0.5rem 0;
    }
}
.workspace-dropdown {
    width: 200px;
}
// Pages with tables
.page-header {
    h3 {
        color: #101828;
        font-family: 'Poppins', sans-serif;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
    }
    p {
        color: #667085;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
}

// Random
  .next-steps .fa-link {
      margin-right: 5px;
  }

/* Fix for use only flexbox in content area */
.next-steps .row {
    margin-bottom: 0;
}

.next-steps .col-md-5 {
    margin-bottom: 3rem;
}

@media (max-width: $bp-md) {
    .next-steps .col-md-5 {
        margin-bottom: 0;
    }
}

.spinner {
    position: absolute;
    display: flex;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background-color: white;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.result-block-container .result-block {
    opacity: 1;
}

// Tags
.tag {
    display: flex;
    padding: 2px 8px 2px 6px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 16px;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    text-align: left;

    &-new {
        color: #3050D2;
        background: #D8DCFF;
    }
    &-approved {
        background: #ECFDF3;
        color: #037847;
    }
    &-pending {
        color: #364254;
        background: #F2F4F7;
    }
}

// Icon Logos
.icon-cell {
    width: 36px;
    height: 36px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
}

// Tables
table {
    font-size: 14px;

    .title-column {
        white-space: nowrap;
        font-weight: 500;
    }
}
.table-actions {
    margin-left: 6px;
    border-radius: 6px;
    padding: 4px;
    color: #4b5563;

    &:hover {
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
        background-color: #fafafa;
    }
}

// Settings
.settings-row {
    margin: 8px 0 20px 0;
}
label {
    display: block;
    color: #434A55;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

// Members Page
.members-card {
    max-width: 820px;
}
.member-item {
    display: flex;
    flex-direction: row;
    align-content: space-between;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .member-left {
        display: flex;
        flex-direction: row;
        align-content: space-between;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;
    }

    .member-right {
        padding-bottom: 10px;
    }

    .member-image {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 1em;
    }

    .member-placeholder {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 1em;
        border: 1px dashed #888888;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        color: #888888;
    }

    .member-contact {
        margin-right: 4em;
        width: 300px;

        .member-name {
            font-weight: bold;
        }

        .member-email {
            color: #888888;
            font-size: 0.9em;
        }

    }
}
.member-status-suspended {
    color: #888888;
}
.status-pill {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 5px;
    display: inline-block;
    height: 20px;
    padding: 1px 8px 1px 8px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-left: 6px;

    &.invited {
        color: #6B6F76;
        background: #EFF1F4;
    }
    &.permissions {
        color: #037847;
        background: #ECFDF3;
    }
}
.card-top-info {
    margin-bottom: 40px;
    button {
        float: right;
    }
}
.member-actions span {
    color: #656B7F;
    padding: 6px;
    border-radius: 6px;

    &:hover {
        background: #F4F6FB;
    }
}
.p-menuitem-link:hover{
    span {
        color: $main;
    }
}
.transparent {
    color: transparent;
    &.p-progressbar-determinate .p-progressbar-label {
        color: transparent;
    }
}

// Prime React Ovvverrides
.p-card {
    box-shadow: 0px -1px 0px 0px #EBECF1 inset, 0px 2px 8px 0px #E2E8F3;
}

// Style from Auth0
.navbar-light.bg-light .nav-link {
    color: #041433;
}

.navbar .router-link-exact-active {
    color: #041433;
    border-bottom: 3px solid #0066f9;
}
.navbar-brand {
    margin-right: 60px;
}
.navbar-nav .nav-link {
    color: #041433;
    padding: 0 0 0.5rem 0;
    box-sizing: border-box;
    margin: 0 40px 0 0;
    font-weight: 400;
}
.navbar-nav .nav-link:hover {
    color: #000104;
}
.navbar-nav .mambo-button {
    min-width: 145px;
}
.nav-user-profile {
    border-radius: 50% 50%;
    max-width: 3.785rem;
    height: auto;
    box-shadow: 0 0 4px 0 #b3bac7;
}
.nav-item.dropdown .dropdown-toggle {
    margin: 0;
}
.nav-item.dropdown .dropdown-name {
    font-weight: 600;
}
.nav-item.dropdown .router-link-exact-active {
    border: 0;
}
.nav-item.dropdown .dropdown-menu {
    box-shadow: 0 0 4px 0 #b3bac7;
}
.nav-item.dropdown .dropdown-header {
    border-bottom: 1px solid #b3bac7;
    font-size: 1rem;
    font-weight: 600;
    color: #041433;
}
.nav-item.dropdown .dropdown-item {
    border-bottom: 1px solid #b3bac7;
    padding: 0.55rem 1.5rem;
}
.nav-item.dropdown .dropdown-item .icon {
    margin-right: 8px;
    vertical-align: middle;
}
.nav-item.dropdown .dropdown-item:first-child:hover {
    background: inherit;
}
.nav-item.dropdown .dropdown-item:last-child {
    border-bottom: 0;
}
@media (min-width: $bp-xs) {
    .container {
        max-width: inherit;
    }
}
@media (min-width: $bp-lg) {
    .container {
        max-width: 960px;
    }
}
@media (min-width: $bs-xxl) {
    .container {
        max-width: 1140px;
    }
}
// switch to mobile nav
@media (max-width: $bp-md - 1px) {
    .nav-item {
        margin-bottom: 1rem;
    }
    .nav-item .nav-link {
        padding: 0 0 0 16px;
    }
    .nav-item .nav-link.router-link-exact-active {
        border-bottom: 0;
        border-left: 3px solid #0066f9;
        padding-left: 13px !important;
        vertical-align: middle;
    }
    .navbar-brand {
        margin-left: 2.5rem;
        vertical-align: top;
    }
    .navbar-toggler {
        margin-right: 2.5rem;
    }
    .navbar-nav {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }
    .navbar-nav:first-child {
        margin-top: 1.5em;
    }
    .navbar-nav:last-child {
        background: #fff;
        box-shadow: 0 -1px 2px 0 #dfe3ec;
        margin: 1.5em 0 0 0;
        padding: 1.5em 2.5rem;
    }
    .navbar-nav:last-child li {
        margin-bottom: 1em;
    }
    .navbar-nav:last-child li .icon {
        margin-right: 1em;
        vertical-align: middle;
    }
    .navbar-nav:last-child li a {
        vertical-align: middle;
        color: #041433;
    }
    .navbar-nav .user-info img {
        margin-right: 1em;
    }
    .navbar-nav .btn-link {
        padding: 0;
        color: #041433;
        min-width: 0;
    }
}
.login-buttons {
    width: 200px;
    margin: auto;
    button {
        width: 100%;
    }
}
.login-logo {
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
}
footer {
    background: #eff1f5;
    box-shadow: 0 -1px 2px 0 #dfe3ec;
    text-align: center;
    padding: 32px;
}
.hero {
    max-width: 500px;
    margin: 10% auto 0 auto;
}
.next-steps {
    padding: 0 32px;
}
.next-steps h2 {
    margin-bottom: 4rem;
}
.next-steps .row {
    margin-bottom: 3rem;
}
@media (max-width: $bp-md) {
    .next-steps .row {
        margin-bottom: 0;
    }
    .next-steps h6 {
        margin-top: 1.5rem;
    }
}
pre {
    width: 100%;
}
.profile-header {
    text-align: center;
    margin-bottom: 3rem;
}
.profile-header .lead {
    font-size: 1.25rem;
}
.profile-header .profile-picture {
    box-shadow: 0 0 4px 0 #b3bac7;
    margin-bottom: 1.5rem;
}
@media (min-width: $bp-md) {
    .profile-header {
        text-align: left;
    }
    .profile-header .profile-picture {
        margin-bottom: inherit;
    }
}
.result-block-container {
    position: relative;
    min-height: 300px;
}
.result-block {
    top: -10px;
    position: absolute;
    opacity: 0;
    transition: all 0.3s;
}
.result-block.show {
    opacity: 1;
    top: 0;
}
@media (min-width: $bp-md) {
    .result-block {
        min-width: $bp-sm;
    }
}
html {
    font-size: 16px;
}
body {
    color: #041433;
}
body,
html {
    height: 100%;
    margin: 0;
    padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
}
h6.muted {
    color: #6c757d;
    text-transform: uppercase;
    font-weight: 300;
    margin-bottom: 1.5rem;
    font-size: 1rem;
}
a {
    color: #0066f9;
}

.mambo-button {
    border-radius: $br-buttons;
}

// logo upload in settings and workspace creation dialog
.upload-logo-container {
    position: relative;
    margin-top: 6px;
    margin-bottom: 12px;
    width: 80px;
    height: 80px;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #DDDDDD;
    display: flex;
    flex: initial;
    flex-direction: row;

    .workspace-logo-container {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        line-height: 0;
        flex-shrink: 0;
        aspect-ratio: 1 / 1;
        width: 80px;
        height: 80px;
        font-size: 20px;

        img {
            width: 100%;
            height: 100%;
            pointer-events: none;
        }
    }
    .logo-upload{
        position: absolute;
        inset: 0;
        background: rgba(0, 0, 0, 0.45);
        opacity: 0;
        display: flex;
        flex: initial;
        flex-direction: row;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;

        &:hover{
            opacity: 1;
            cursor: pointer;
        }

        .upload-text {
            font-style: normal;
            text-align: left;
            color: rgb(238, 239, 252);
            line-height: normal;
            font-size: var(--font-size-miniPlus);
            font-weight: 500;

        }
    }
}
#logo-help {
    color: #969EAC;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    display: block;
    margin-top: 6px;
}
// divider
.p-divider.p-divider-horizontal:before {
    border-top: 1px solid #313131;
}
// dialogs
.dialog-header-center {
    text-align: center;
}
.p-dialog {
    .p-dialog-header {
        padding: 30px 30px 0 30px;
    }
    .subheader {
        text-align: center;
        font-size: 14px;
    }
    .p-dialog-content {
        padding: 2px 40px 30px 40px;
    }
    input {
        width: 100%;
    }
}
@media (max-width: $bp-md - 1px) {
    .md-hide {
        display: none;
    }
}
.w-200 {
    width: 200px;
}
